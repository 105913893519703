import logo from './logo.svg';
import './App.css';
import CenteredGif from './Gif';
function App() {
  return (
    <div className="App">
        <p className="site-heading">
         Sean Really
        </p>
       <CenteredGif/>
    </div>
  );
}
export default App;
